<template>
	<div class="settlement">
		<mallHeader />
		<div class="mall-settlement container">
			<ul class="breadcrumb">
				<li>
					<a href="#" @click="$router.push('/ori-mall')"
						><i class="el-icon-s-home"></i
					></a>
				</li>
				<li><i class="el-icon-arrow-right"></i></li>
				<li><a href="#">结算页</a></li>
			</ul>
			<h4>填写并核对订单信息</h4>
			<div class="information">
				<div class="consigneeInfo">
					<div class="consigneeInfo-top">
						<span class="headerInfo">收货人信息</span>
						<div class="new-address" @click="dialogAddress = true">
							新增收货地址
						</div>
					</div>
					<ul>
						<li>
							<div class="address-choose">家</div>
							<div class="address-detail">
								<span class="addr-name">呼呼</span>
								<span class="addr-info"
									>{{ citydata }} 呼呼呼呼呼呼呼呼呼呼</span
								>
								<span class="addr-tel">12345678911</span>
								<span class="addr-defaule">默认地址</span>
								<span class="addr-edit" @click="dialogAddress = true,addressShow = false">编辑</span>
							</div>
						</li>
					</ul>
					<div class="more-address">
						更多地址<i class="el-icon-arrow-down"></i>
					</div>
				</div>
				<div class="delivery-list">
					<div class="delivery-list-top">
						<span class="delivery-list-text">送货清单</span>
						<div class="delivery-right">
							<div class="price-description">
								<i class="el-icon-warning-outline"></i>
								价格说明
							</div>
							<div class="go-cart" @click="$router.push('/shoppingCart')">
								返回修改购物车
							</div>
						</div>
					</div>
					<div class="goods-list">
						<div class="my-goods-item" v-for="item in list" :key="item.id">
							<div class="left">
								<div class="custom-control custom-checkbox">
									<label class="custom-control-label" :for="item.id">
										<img :src="item.goods_img" alt="" />
									</label>
								</div>
							</div>
							<div class="right">
								<div class="top">{{ item.goods_name }}</div>
								<div class="bottom">
									<span class="price">¥ {{ item.goods_price }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
        <div class="pay">
					<div class="pay-top">
						支付方式
					</div>
					<div class="pay-choose">在线支付</div>
					<hr />
				</div>
				<div class="invoice-info">
					<div class="invoice-info-top">发票信息</div>
					<div class="invoice-modify">
						不开发票<span class="modify">修改</span>
					</div>
				</div>
				<div class="preferential">
					使用优惠/礼品卡/抵用<i class="el-icon-arrow-down"></i>
				</div>
			</div>
			<div class="total-price">
				<div class="total-amount">
					<span>总商品金额：</span>
					<span>￥12999.00</span>
				</div>
				<div class="freight"><span>运费：</span><span>￥0.00</span></div>
			</div>
			<div class="footer-detail">
				<span class="totel-text">应付总额：</span>
				<span class="price">￥12999.00</span>
				<div class="footer-address">
					寄送至： 上海 青浦区 盈浦街道 盈港东路8000弄199号302 收货人：柯潜
					176****6502
				</div>
			</div>
			<div class="submit-orders" @click="$router.push('/checkstand')">
				提交订单
			</div>
			<el-dialog
				:title="addressShow ? '新增收货地址' : '修改收货地址'"
				:visible.sync="dialogAddress"
				width="30%"
				:before-close="handleClose"
			>
				<el-form
					:label-position="labelPosition"
					label-width="80px"
					:model="formLabelAlign"
				>
					<el-form-item label="收货人:">
						<el-input v-model="formLabelAlign.name"></el-input>
					</el-form-item>
					<el-form-item label="手机号:">
						<el-input v-model="formLabelAlign.phone"></el-input>
					</el-form-item>
					<el-form-item label="地址:">
						<v-distpicker @selected="sel"></v-distpicker>
					</el-form-item>
					<el-form-item label="详细地址:">
						<el-input v-model="formLabelAlign.address"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogAddress = false">取 消</el-button>
					<el-button type="primary" @click="dialogAddress = false"
						>确 定</el-button
					>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import mallHeader from './header.vue'
import VDistpicker from 'v-distpicker'
export default {
	name: 'product',
	components: {
		mallHeader,
		VDistpicker,
	},
	data() {
		return {
			dialogAddress: false,
			addressShow: true,
			labelPosition: 'right',
			citydata: '',
			formLabelAlign: {
				name: '',
				region: '',
				type: '',
			},
			list: [
				{
					goods_count: 1,
					goods_img: 'https://www.escook.cn/vuebase/pics/1.png',
					goods_name:
						'班俏BANQIAO超火ins潮卫衣女士2020秋季新款韩版宽松慵懒风薄款外套带帽上衣',
					goods_price: 108,
					goods_state: true,
					id: 1,
				},
			],
		}
	},
	methods: {
		handleClose(done) {
			this.$confirm('确认关闭？')
				.then(() => {
					done()
				})
				.catch(() => {})
		},
		sel(data) {
			this.citydata =
				data.province.value + ' ' + data.city.value + ' ' + data.area.value
			console.log(this.citydata)
		},
	},
}
</script>

<style lang="scss" scoped>
.settlement {
	color: #666;
	// background-color: #f0f3ef;
  font-family: fangsong;
	li {
		list-style: none;
	}
	.container {
		width: 1200px;
		margin: 0 auto;
	}
	.mall-settlement {
		.breadcrumb {
			margin: 23px 0;
			background-color: transparent;
			padding: 0;
			display: inline-block;
			width: 100%;
			line-height: 100%;
			li {
				display: inline-block;
				padding-left: 8px;
				margin-right: 7px;
				position: relative;
				a {
					display: block;
					padding: 0;
					color: #999;
					text-decoration: none;
					font-size: 14px;
				}
				a:hover {
					color: #005ea6;
				}
				i {
					font-size: 14px;
				}
			}
		}
		.information {
			border: 1px solid #d7d7d7;
			.consigneeInfo {
				padding: 20px 20px 0 20px;
				.consigneeInfo-top {
					display: flex;
					justify-content: space-between;
					.headerInfo {
						font-weight: 700;
						color: #222;
					}
					.new-address {
						cursor: pointer;
						color: #005ea6;
					}
					.new-address:hover {
						color: #d12;
					}
				}
				ul {
					padding: 20px 20px 0 20px;
					li {
						display: flex;
						align-items: center;
						font-size: 14px;
						.address-choose {
							width: 150px;
							height: 30px;
							border: 2px solid #005ea6;
							text-align: center;
							line-height: 27px;
						}
						.address-detail {
							padding: 0 20px;
							width: 100%;
							.addr-info {
								margin-left: 20px;
							}
							.addr-tel {
								margin: 0 20px;
							}
							.addr-defaule {
								background-color: #999;
								height: 30px;
								color: #fff;
								display: inline-block;
								width: 70px;
								text-align: center;
								line-height: 30px;
							}
							.addr-edit {
								color: #005ea6;
								float: right;
								cursor: pointer;
								opacity: 0;
							}
							.addr-edit:hover {
								color: #d12;
							}
						}
						.address-detail:hover {
							background-color: rgba(255, 185, 185, 0.5);
							line-height: 30px;
						}
						.address-detail:hover .addr-edit {
							opacity: 1;
						}
					}
				}
				.more-address {
					font-size: 14px;
					margin-left: 20px;
					padding-bottom: 20px;
					cursor: pointer;
					border-bottom: 1px solid #d7d7d7;
				}
			}
			.pay {
				padding: 0 20px 0 20px;
				.pay-top {
					font-weight: 700;
					color: #222;
				}
				.pay-choose {
					width: 150px;
					height: 30px;
					border: 2px solid #005ea6;
					text-align: center;
					line-height: 27px;
					margin: 20px;
					font-size: 14px;
				}
			}
			.delivery-list {
				padding: 20px 20px;
				.delivery-list-top {
					display: flex;
					justify-content: space-between;
					.delivery-list-text {
						font-weight: 700;
						color: #222;
					}
					.delivery-right {
						color: #005ea6;
						display: flex;
						cursor: pointer;
						.go-cart {
							margin-left: 20px;
						}
						.go-cart:hover {
							color: #d12;
						}
						.price-description {
							i {
								color: red;
							}
						}
					}
				}
				.goods-list {
					.my-goods-item {
						display: flex;
						border-bottom: 1px solid #ccc;
						background-color: #fff;
						padding: 20px 20px;
						.left {
							img {
								width: 120px;
								height: 120px;
								margin-right: 20px;
								border-radius: 10px;
							}
							.custom-control-label::before,
							.custom-control-label::after {
								top: 50px;
							}
						}
						.right {
							flex: 1;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							.bottom {
								display: flex;
								justify-content: space-between;
								padding: 5px 0;
								.price {
									color: red;
									font-weight: bold;
								}
							}
						}
					}
					// background: yellow;
				}
			}
			.invoice-info {
				padding: 0 20px 0 20px;
				.invoice-info-top {
					color: #222;
					font-weight: 700;
				}
				.invoice-modify {
					margin-top: 20px;
					padding-bottom: 20px;
					padding-left: 20px;
					font-size: 14px;
					border-bottom: 1px solid #d7d7d7;
					.modify {
						margin-left: 10px;
						color: #005ea6;
						cursor: pointer;
					}
					.modify:hover {
						color: #d12;
					}
				}
			}
			.preferential {
				padding: 20px 20px 10px 20px;
				color: #222;
				font-weight: 700;
			}
		}
		.total-price {
			float: right;
			span {
				display: inline-block;
				width: 100px;
				height: 20px;
				text-align: right;
				margin: 20px;
				font-size: 14px;
			}
		}
		.footer-detail {
			text-align: right;
			margin-top: 120px;
			height: 100px;
			background: #f0f3ef;
			font-size: 14px;
			border-top: 1px solid #d7d7d7;
			margin-bottom: 20px;
			span {
				display: inline-block;
				width: 100px;
				height: 20px;
				text-align: right;
				margin: 20px;
				font-size: 14px;
			}
			.price {
				font-weight: 700;
				color: #d12;
				font-size: 18px;
			}
			.footer-address {
				padding: 0 20px;
				font-size: 14px;
			}
		}
		.submit-orders {
			width: 135px;
			height: 50px;
			background-color: #005ea6;
			color: #fff;
			font-size: 22px;
			font-weight: 700;
			border-radius: 5px;
			text-align: center;
			line-height: 50px;
			float: right;
			cursor: pointer;
		}
		.submit-orders:hover {
			background-color: #d12;
		}
	}
}
::v-deep {
	// .el-dialog__body {
	// 	overflow-y: scroll;
	// 	height: 70%;
	// }
	// .el-dialog {
	// 	height: 50%;
	// }
	.distpicker-address-wrapper select {
		padding: 0;
	}
}
</style>
